<template>
  <div class="pa-0">
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <!-- lates news and stories -->
    <div
      class="grid justify-items-center px-0 py-10"
      v-if="news.length > 0 || newsPast.length > 0">
      <v-col cols="11" class="pa-0 mt-10 pt-10">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-4">
            <span class="font-bold text-2xl" v-if="bahasa">Berita</span>
            <span class="font-bold text-2xl" v-else>News</span>
          </v-col>
        </v-row>
        <v-row class="px-3" v-if="news.length > 0">
          <v-col
            cols="12"
            sm="7"
            class="text-left cursor-pointer"
            @click="goTo('news-detail', news[0].id)">
            <img
              :src="baseUrlImage + news[0].image.file_path"
              @error="
                $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
              "
              width="100%"
              class="rounded-md" />
            <div class="font-bold text-2xl sm:text-3xl pa-0 mt-5">
              {{ news[0] ? news[0].title : "-" }}
            </div>
            <div class="text-sm py-2 pa-0">
              {{ news[0] ? news[0].lastUpdate : "" }}
            </div>
            <div
              class="font-lora pa-0 text-base h-24 sm:h-14"
              v-html="strLimit(news[0].desc, 100)"></div>
            <v-btn
              @click="goTo('news-detail', news[0].id)"
              class="btn-primary mt-3 font-bold text-capitalize">
              <span v-if="bahasa">Baca selengkapnya</span>
              <span v-else>Read More</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="5" class="text-left pt-0">
            <div
              class="flex mb-2 cursor-pointer"
              @click="goTo('news-detail', item.id)"
              v-for="(item, index) in news"
              :key="index + 'news'">
              <template v-if="index > 0">
                <v-col cols="3" class="px-0">
                  <img
                    :src="baseUrlImage + item.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md sm:px-3" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-base pt-0 sm:text-xl">
                    {{ item.title || "-" }}
                  </div>
                  <div class="text-sm py-2 pa-0">
                    {{ item.lastUpdate || "-" }}
                  </div>
                </v-col>
              </template>
            </div>
          </v-col>
        </v-row>
        <div class="mt-10">
          <v-col cols="12" class="text-2xl font-bold text-left mb-4">{{
            bahasa ? "Berita lalu" : "Older"
          }}</v-col>
          <v-skeleton-loader
            type="paragraph"
            class="mt-3"
            v-if="isLoadPast"></v-skeleton-loader>
          <v-row v-if="newsPast.length > 0">
            <v-col
              cols="12"
              sm="4"
              v-for="(item, index) in newsPast"
              :key="index"
              class="px-5 text-left cursor-pointer"
              @click="goTo('news-detail', item.id)">
              <img
                :src="baseUrlImage + item.image.file_path"
                @error="
                  $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
                "
                width="100%"
                class="rounded-md h-52" />
              <div class="font-bold text-base sm:text-xl py-2">
                {{ item.title || "-" }}
              </div>
              <div class="text-sm py-2 pa-0">{{ item.lastUpdate || "-" }}</div>
              <div
                class="font-lora pa-0 text-base h-24 sm:h-14"
                v-html="strLimit(item.desc, 100)" />
            </v-col>
          </v-row>
        </div>
        <v-row class="text-left mt-3 py-10" v-if="newsPast.length > 0">
          <v-spacer />
          <v-col cols="auto">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="green darken-1"
              class="my-10"
              next-icon="navigate_next"
              prev-icon="chevron_left"></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <v-col
      cols="11"
      class="pa-0 mt-10 py-10"
      v-if="news.length === 0 && newsPast.length === 0">
      <v-row>
        <v-col cols="12" sm="6">
          <img src="@/assets/images/405.png" width="100%" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="text-black flex flex-wrap content-center">
          <v-col cols="12" class="text-3xl font-bold pl-0">{{
            bahasa
              ? "Tidak Ada Berita Layanan yang Tersedia"
              : "No News Data Available"
          }}</v-col>
          <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
            {{ bahasa ? "kembali ke beranda" : "back to home" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      per_page: 9,
      page: 1,
      pageCount: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      news: [],
      newsPast: [],
      isLoad: false,
      isLoadPast: false,
    };
  },
  watch: {
    language() {
      this.getData();
      this.getDataPast();
    },
    page() {
      this.getDataPast();
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
    this.getDataPast();
  },
  methods: {
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        page: this.page,
        option: "NEW",
        type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDataPast() {
      this.isLoadPast = true;
      let param = {
        lang: this.language,
        page: this.page,
        per_page: this.per_page,
        option: "OLD",
        type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          this.newsPast = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pageCount = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadPast = false));
    },
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
  },
};
</script>
